import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ReseteoContra() {
    const [usuario, setUsuario] = useState('');
    const [correo, setCorreo] = useState('');
    const [contraseña, setContraseña] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [mensaje, setMensaje] = useState(''); // Estado para el mensaje de notificación

    // Obtener usuarios del backend
    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await axios.get('https://backendjarciplas.onrender.com/api/usuarios');
                setUsuarios(response.data);
            } catch (error) {
                console.error('Error al obtener los usuarios:', error);
                setMensaje('Error al cargar los usuarios'); // Notificación en caso de error
            }
        };

        fetchUsuarios();
    }, []); // Solo se ejecuta una vez al montar el componente

    // Función para enviar los datos actualizados al backend
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Usuario:', usuario);   
        console.log('Correo:', correo);
        console.log('Contraseña:', contraseña);

        try {
            // Enviar los datos actualizados a la API
            const response = await axios.put('https://backendjarciplas.onrender.com/api/usuarios/:id', {
                usuario,
                correo,
                contraseña,
            });
            console.log('Usuario actualizado:', response.data);

            // Mostrar mensaje de éxito
            setMensaje('Usuario actualizado correctamente');

            // Salir del modo edición después de enviar los datos
            setIsEditing(false);

            // Actualizar la lista de usuarios si es necesario
            setUsuarios(usuarios.map((user) =>
                user.Correo === correo ? { ...user, Nombre: usuario, Contraseña: contraseña } : user
            ));

        } catch (error) {
            console.error('Error al actualizar el usuario:', error);

            // Mostrar mensaje de error
            setMensaje('Error al actualizar el usuario');
        }
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing); // Alternar entre ver y editar
        setMensaje(''); // Limpiar mensaje cuando se cambia al modo de edición
    };

    return (
        <div>
            <h2>Reseteo de Contraseña</h2>

            {/* Mostrar el mensaje si existe */}
            {mensaje && <div style={{ padding: '10px', backgroundColor: mensaje.includes('Error') ? '#f8d7da' : '#d4edda', color: mensaje.includes('Error') ? '#721c24' : '#155724', borderRadius: '5px', marginBottom: '15px' }}>
                {mensaje}
            </div>}

            {!isEditing ? (
                <div>
                    <h3>Selecciona un usuario para editar</h3>
                    <ul>
                        {usuarios.map((user) => (
                            <li key={user.id_usuarios}>
                                <strong>Usuario:</strong> {user.Nombre} {user.Apellido_Paterno}
                                <button onClick={() => {
                                    setUsuario(user.Nombre);
                                    setCorreo(user.Correo);
                                    setContraseña(user.Contraseña);
                                    toggleEdit();
                                }}>Editar</button>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label>
                        Usuario:
                        <input 
                            type="text" 
                            value={usuario} 
                            onChange={(e) => setUsuario(e.target.value)} 
                        />
                    </label>
                    <label>
                        Correo:
                        <input 
                            type="email" 
                            value={correo} 
                            onChange={(e) => setCorreo(e.target.value)} 
                            disabled
                        />
                    </label>
                    <label>
                        Contraseña:
                        <input 
                            type="password" 
                            value={contraseña} 
                            onChange={(e) => setContraseña(e.target.value)} 
                        />
                    </label>
                    <button type="submit">Guardar</button>
                    <button type="button" onClick={toggleEdit}>Cancelar</button>
                </form>
            )}
        </div>
    );
}

export default ReseteoContra;
