import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './Ventanas/AuthContext';
import axios from 'axios';
import './index.css';

const Navbar = () => {
  const { usuario, cerrarSesion } = useAuth();
  const navigate = useNavigate();

  const [empresa, setEmpresa] = useState({ nombre: '' });
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // Nuevo estado para manejar el menú en móviles

  useEffect(() => {
    const obtenerInformacionEmpresa = async () => {
      try {
        const response = await axios.get('https://backendjarciplas.onrender.com/api/informacion');
        if (response.data && response.data.length > 0) {
          setEmpresa({ nombre: response.data[0].nombre });
        }
      } catch (error) {
        console.error('Error al obtener la información de la empresa', error);
      }
    };

    obtenerInformacionEmpresa();
  }, []);

  const handleCerrarSesion = () => {
    cerrarSesion();
    navigate('/');
  };

  const toggleDropdown = (menu) => {
    setDropdownOpen(dropdownOpen === menu ? null : menu);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.dropdown')) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">{empresa.nombre}</Link>
      </div>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        ☰
      </button>
      <ul className={`navbar-links ${menuOpen ? 'show' : ''}`}>
        {usuario === null ? (
          <>
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/Login">Login</Link></li>
            <li><Link to="/Registro">Registro</Link></li>
          </>
        ) : usuario === 'usuario' ? (
          <>
            <li><Link to="/Catalogo">Catálogo</Link></li>
            <li><Link to="/PerfilUsuario">Mi Perfil</Link></li>
            <li><button className="btncerr" onClick={handleCerrarSesion}>Cerrar Sesión</button></li>
          </>
        ) : (
          <>
            <li><Link to="/Productos">Productos</Link></li>
            <li><Link to="/Adminrecuperacion">RecuperarContraseña</Link></li>
            <li><Link to="/AdminDatosEmpresa">Información Empresa</Link></li>
            <li>
              <div className="dropdown">
                <button onClick={() => toggleDropdown('usuarios')} className="dropdown-btn">
                  Administración usuarios
                </button>
                {dropdownOpen === 'usuarios' && (
                  <ul className="dropdown-content">
                    <li><Link to="/AdminConfiguracion">Configuraciones</Link></li>
                    <li><Link to="/Roles">Roles</Link></li>
                    <li><Link to="/AdminBloqueos">Bloqueos</Link></li>
                  </ul>
                )}
              </div>
            </li>
            <li>
              <div className="dropdown">
                <button onClick={() => toggleDropdown('regulatorios')} className="dropdown-btn">
                  Documentos regulatorios
                </button>
                {dropdownOpen === 'regulatorios' && (
                  <ul className="dropdown-content">
                    <li><Link to="/AdminPoliticas">Políticas</Link></li>
                    <li><Link to="/AdminTerminos">Términos</Link></li>
                    <li><Link to="/AdminDeslinde">Deslinde</Link></li>
                  </ul>
                )}
              </div>
            </li>
            <li><button className="btncerr" onClick={handleCerrarSesion}>Cerrar Sesión</button></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
